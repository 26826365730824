@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Hotspot {
    position: absolute;
    height: 8px;
    width: 8px;
    animation: hotspotFadeIn ease-in-out 150ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes hotspotFadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &-Hover {
        span {
            display: block;
            height: 8px;
            width: 8px;
            z-index: 1;
            background: #00E600;
            border-radius: 100%;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            background: $black;
            opacity: 0.1;
            inset-block-start: 50%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
        }

        &::before {
            height: 16px;
            width: 16px;
        }

        &::after {
            height: 32px;
            width: 32px;
        }
    }

    &-Popper {
        z-index: 10;

        @include desktopAndAbove {
            &::before {
                content: '';
                display: block;
                position: absolute;
                background: transparent;
                height: calc(100% + 50px);
                width: 100%;
                inset-block-start: -25px;
                inset-block-end: -25px;
                inset-inline-start: 0;
            }
        }

        &[data-popper-placement*="bottom"] .Hotspot-Arrow {
            inset-block-start: 0;
            margin-block-start: -8px;

            &::before {
                border-width: 0px 8px 8px;
                border-color: transparent transparent $white;
            }
        }

        &[data-popper-placement*="top"] .Hotspot-Arrow {
            inset-block-end: 0;
            margin-block-end: -8px;

            &::before {
                border-width: 8px 8px 0;
                border-color: $white transparent transparent;
            }
        }
    }

    &-Arrow {
        width: 19px;
        height: 8px;

        &::before {
            content: "";
            margin: auto;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
        }
    }

    &-Content {
        width: 216px;
        background: $white;
        border: 1px solid $gray-light;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

        .Image {
            height: auto;
            width: 100%;
            padding-block-end: 56.25%;
        }
    }

    &-Title {
        font-size: $fs-xs;
        font-weight: $fw-regular;
        line-height: 20px;
        margin-block-end: 5px;
    }

    &-Description {
        height: auto;

        * {
            font-size: $fs-xs;
            font-weight: $fw-light;
            line-height: 20px;
        }
    }

    &-LowestPrice {
        font-weight: $fw-regular;
        font-size: $fs-xxxs;
        color: $black;
        margin-block-end: 3px;
        line-height: 16px;
        width: 100%;

        span {
            color: $black;
        }
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-Price {
        width: 100%;

        @include desktop {
            font-size: $fs-xl;
        }
    }

    .ProductPrice {
        &-PriceValue {
            @include mobile {
                font-size: $fs-xs;
            }
        }

        del {
            @include mobile {
                font-size: $fs-xxxs;
            }
        }
    }

    &-Link {
        display: block;
        position: static;
        padding: 12px;

        &>* {
            pointer-events: none;
        }
    }

    &-Qmeters {
        width: auto;
        padding-inline-start: 2px;

        sup {
            font-size: 60%;
            inset-block-start: -0.5em;
        }
    }
}
