@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ImageWithHotspots {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;

    &_isScrollable {
        @include mobile {
            margin-inline: 16px;
            min-width: 800px;
            min-height: 500px;
            padding-block-end: 0;
        }

        >.Image {
            @include mobile {
                padding-block-end: 0;
            }
        }

        .Image-Image {
            @include mobile {
                position: static;
            }
        }
    }

    &-Scrollable {
        height: 100%;
        width: 100%;

        &-Wrapper {
            position: absolute;
            height: 100%;
            width: 100%;
            overflow-y: hidden;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &>.Image {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
