//colors
$white: #fff;
$white70: rgba(255, 255, 255, 0.7);
$gold: #DB8800;
$gold50: rgba(255, 222, 168, 0.5);
$orange-dark: #A34400;
$green: #006F00;
$green2: #00A800;
$green-light: #C9FFB3;
$red: #BA1B1B;
$gray: #4F5759;
$gray2: #666666;
$gray3: #D9D9D9;
$gray4: #797979;
$gray5: #A1A1A1;
$gray6: #BCBCBC;
$gray-semi-transparent: rgba(79, 87, 89, .6);
$gray-transparent20: rgba(79, 87, 89, .2);
$gray-light: #DDDDDD;
$gray-light2: #F2F2F2;
$gray-light3: #F7F7F7;
$gray-light4: #E6E6E6;
$gray-light5: #F3F3F3;
$gray-light6: #F8F8F8;
$gray-light7: #f3f4f4;
$gray-lighter: #EEEEEE;
$black: #191C1D;
$black2: #191D1D;
$black80: rgba(25, 28, 29, 0.8);

$pure-black-10: rgba($black, 0.1);
$light-grey-60: rgba(36, 38, 43, 0.6);
$light-grey-input: #e0e0e0;
$light-grey-5: rgba(36, 38, 43, 0.05);
$light-grey-15: rgba(36, 38, 43, 0.15);

$color-primary: #10069F;
$color-primary-light: #4A4DCE;
$color-primary-lighter: rgba($color-primary-light, 0.15);
$color-primary-darker: #08034F;

$color-secondary: #FFDEA8;
$color-secondary-light: rgba($color-secondary, 0.7);
$color-secondary-lighter: rgba($color-secondary, 0.35);

$color-tertiary: #BEF500;
$color-tertiary-lighter: rgba($color-tertiary, 0.35);

$accept: $green;
$error: $red;
$warning: $orange-dark;


$default-primary-base-color: $color-primary;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);

// Fonts
$default-font: 'Inter', sans-serif;
$font-muli: $default-font;
$font-standard-size: 62.5%;

$fw-bold: 700;
$fw-semi-bold: 600;
$fw-medium: 500;
$fw-regular: 400;
$fw-light: 300;

$fs-xxxs: 10px;
$fs-xxs: 12px;
$fs-xs: 14px;
$fs-s: 16px;
$fs-m: 18px;
$fs-l: 22px;
$fs-xl: 24px;
$fs-xxl: 28px;
$fs-xxxl: 32px;
$fs-xxxxl: 48px;

$scrollbar-track-color: $gray-light;
$scrollbar-thumb-color: #5F686B;

$shadow-basic: 0px 111px 80px rgba(0, 0, 0, 0.07), 0px 51px 29px rgba(0, 0, 0, 0.0566335), 0px 28px 14px rgba(0, 0, 0, 0.0542652), 0px 15px 7px rgba(0, 0, 0, 0.0540841), 0px 4px 4px rgba(0, 0, 0, 0.0529444), 0px 4px 2px rgba(0, 0, 0, 0.0481137), 0px 1px 1px rgba(0, 0, 0, 0.0351498);
