@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductWithHotspotsWidget {
    @include mobileAndTablet {
        padding-block: 32px;

        &::before {
            content: "";
            position: absolute;
            inset-inline-end: 0;
            inset-block: 0;
            height: 30%;
            width: 100%;
            background: $color-tertiary-lighter;
            z-index: -1;
        }
    }

    @include tablet {
        margin-inline: -32px;
        padding-inline: 32px;
    }

    @include mobile {
        margin-inline: -16px;
        padding-inline: 16px;

        &::before {
            height: 40%;
        }
    }

    @include desktop {
        padding: 24px 0;
    }

    &-Wrapper {
        @include desktopAndAbove {
            display: grid;
            grid-template-columns: 60% auto;
            padding-block: 60px;
            margin-block-start: -50px;

            &::before {
                content: "";
                position: absolute;
                inset-inline-end: 0;
                inset-block: 0;
                height: 100%;
                width: 60%;
                background: $color-secondary-lighter;
                z-index: -1;
            }
        }
    }

    &-Content {
        @include desktopAndAbove {
            align-self: center;
            padding-inline: 25% 32px;
        }

        @include mobileAndTablet {
            margin-block-start: 16px;
        }

        @include tablet {
            margin-block-start: 32px;
        }
    }

    &-Name {
        @include mobileAndTablet {
            text-align: center;
        }
    }

    &-Description {
        margin-block-end: 32px;
        font-weight: $fw-light;

        @include mobileAndTablet {
            text-align: center;
        }

        * {
            font-weight: $fw-light;
        }
    }

    &-Button {
        --hollow-button-background: #{$color-tertiary};
        --hollow-button-hover-background: #{$color-tertiary};
        --hollow-button-padding: 36px;
        --hollow-button-hover-padding: 36px;

        border: none; 

        @include mobileAndTablet {
            width: 100%;
        }
    }

    .ProductWithHotspotsWidget {
        &-Tags {
            display: flex;
            flex-wrap: wrap;
            margin-block-end: 24px;
            gap: 5px;
        }

        &-Tag {
            padding-inline: 4px;
            padding-block: 4px;
            border: 1px solid $pure-black-10;
            font-size: $fs-xs;
            font-weight: $fw-regular;
            line-height: 20px;
            cursor: pointer;
            background: transparent;

            &:hover {
                background: $color-primary-lighter;
            }
        }

        &-Hashtag {
            padding-inline-end: 4px;
        }

        &-ToggleButton {
            height: 30px;
            border: 1px solid $pure-black-10;
            width: 30px;

            >span {
                padding: 2px 4px 1px 2px;

                svg {
                    fill: $pure-black-10;
                }
            }
        }

        &-Image {
            @include mobile {
                overflow-x: scroll;
                margin-inline: -16px;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}
